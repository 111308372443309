import "./managementAgreement.scss";
import { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
  Alert,
  Button,
  Card,
  Form,
  Row,
  Col,
  Modal,
  Spinner,
  Dropdown,
  Badge,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import ErrorPage from "../../utilities/errorPage";
import LoadingPage from "../../utilities/loadingPage";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";

export default function ManagementAgreement() {
  const [data, setData] = useState({});
  const [isComplete, setIsComplete] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [signature, setSignature] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sign, setSign] = useState("open");
  const [show, setShow] = useState(false);
  const [bosID, setBosID] = useState("");
  const [acknowledge, setAcknowledge] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [validInput, setValidInput] = useState(false);
  const [showDraw, setShowDraw] = useState(false);
  const [forCompany, setForCompany] = useState(false);
  const [organizationName, setOrganizationName] = useState(null);
  const pad = useRef();
  const history = useHistory();
  const { management_id } = useParams();

  useEffect(() => {
    getDocumentData();
    const getBosID = sessionStorage.getItem("bos");
    setBosID(getBosID);
  }, []);

  const handleCheckInputs = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidInput(false);
    }
    event.preventDefault();
    setValidInput(true);
  };

  const getDocumentData = () => {
    const requestOptions = {
      method: "GET",
      headers: { "content-type": "application/json" },
      credentials: "omit",
    };
    try {
      fetch(
        process.env.REACT_APP_API + "/document/" + management_id + "/mgmt",
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.data[0]) {
            setData(res.data[0]);
            setLoading(false);
            if (res.data[0].complete || res.data[0].party_one_data !== null) {
              setIsComplete(true);
              const getBosID = sessionStorage.getItem("bos");
              if (getBosID !== null) {
                history.push("/bos/" + getBosID);
              } else {
                setLoading(false);
              }
            }
          } else {
            setLoading(false);
            setError(true);
          }
        });
    } catch (err) {
      setError(true);
      console.log(err);
    }
  };

  const captureSig = () => {
    let signatureCapture = pad.current.toDataURL();
    setSign("complete");
    setSignature(signatureCapture);
  };

  const createTextSignature = (id) => {
    // create the element
    const div = document.createElement("div");
    div.innerHTML = firstName + " " + lastName;
    div.id = id ?? "sig1";
    div.classList.add("create-signature");
    document.body.appendChild(div);
    // convert the element to a base64 image
    html2canvas(div, { scale: 2.5 }).then((canvas) => {
      const base64Image = canvas.toDataURL();
      setSignature(base64Image);
    });
    document.body.removeChild(div);
  };

  useEffect(() => {
    if (signature !== "") {
      if (!acknowledge) {
        checkboxRef?.current?.focus();
        Swal.fire({
          icon: "info",
          title:
            "You must acknowledge that you have received and read the white paper.",
          text: "There is a check box a few lines above the signature area.",
        });
        return;
      }
      setShow(true);
    }
  }, [signature, acknowledge]);
  const checkboxRef = useRef(null);
  const handleSubmit = () => {
    if (!acknowledge) {
      checkboxRef?.current?.focus();
      Swal.fire({
        icon: "info",
        title: "You must acknowledge that you have read the white paper.",
      });
      return;
    }
    if (signature === "") {
      Swal.fire({
        icon: "info",
        title: "Please sign the document before submitting.",
      });
      return;
    }
    setLoading(true);
    let storage = {
      name: firstName + " " + lastName,
      address,
      city,
      state,
      phone,
      email,
    };
    let data = {
      buyerFirstName: firstName,
      buyerLastName: lastName,
      buyerAddress: address,
      buyerCity: city,
      buyerState: state,
      buyerPhone: phone,
      buyerEmail: email,
      buyerSignature: signature,
      buyerUserAgent: navigator.userAgent,
      forCompany,
      organizationName,
    };
    sessionStorage.setItem("firstName", firstName);
    sessionStorage.setItem("lastName", lastName);
    sessionStorage.setItem("organizationName", organizationName);
    localStorage.setItem("documentData", JSON.stringify(storage));

    const requestOptions = {
      method: "POST",
      headers: { "content-type": "application/json" },
      credentials: "omit",
      body: JSON.stringify({ data }),
    };
    try {
      fetch(
        process.env.REACT_APP_API + "/document/sign/mgmt/" + management_id,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.message === "success") {
            Swal.fire({
              icon: "success",
              title: "Document Successfully Signed",
            }).then(() => {
              if (bosID !== null) {
                console.log(bosID);
                history.push("/bos/" + bosID);
              } else {
                console.log("no bos found");
                window.location.reload();
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title:
                "Sorry we are having technical issues, please reach out to support@goodalways.com",
            });
          }
        });
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleChangeForCompany = (e) => {
    if (e.target.value === "true") {
      setForCompany(true);
    } else {
      setForCompany(false);
      setOrganizationName(null);
    }
  };
  const handleReturnName = () => {
    if (forCompany) {
      return organizationName;
    } else {
      return firstName + " " + lastName;
    }
  };

  return isError ? (
    <ErrorPage />
  ) : isComplete ? (
    <div className="onboarding-demo">
      <div className="document-page-bar">
        <img src="/lasazucenas.png" /> Las Azucenas Document System
      </div>
      <div className="paper-container">
        <div className="paper">
          <Alert variant="success">
            Management Services Agreement:
            <hr />
            This Document has been completed. You may close this window.
          </Alert>
        </div>
      </div>
    </div>
  ) : loading ? (
    <LoadingPage title="Loading..." />
  ) : (
    <div className="onboarding-demo">
      <div className="document-page-bar">
        <img src="/lasazucenas.png" /> Las Azucenas Document System
      </div>
      <div className="paper-container">
        <div className="paper">
          <Card>
            <Card.Header>
              Please fill out these fields. This information will be used to
              populate your management services agreement.{" "}
            </Card.Header>
            <Card.Body>
              <Form validated={validInput} onSubmit={handleCheckInputs}>
                <Form.Label>
                  Are you signing for yourself or on behalf of an organization?
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Label>I am signing:</Form.Label>
                    <Form.Select
                      disabled={validInput}
                      required
                      onChange={(e) => handleChangeForCompany(e)}
                    >
                      <option value="false">for myself</option>
                      <option value="true">on behalf of an organization</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    {forCompany ? (
                      <>
                        <Form.Label>Organization Name</Form.Label>
                        <Form.Control
                          disabled={validInput}
                          required
                          value={organizationName}
                          onChange={(e) => setOrganizationName(e.target.value)}
                          placeholder="Organization Name"
                        ></Form.Control>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      disabled={validInput}
                      required
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      type="text"
                      placeholder="First Name"
                    ></Form.Control>
                  </Col>
                  <Col sm>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      disabled={validInput}
                      required
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      type="text"
                      placeholder="Last Name"
                    ></Form.Control>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      disabled={validInput}
                      required
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      type="text"
                      placeholder="Address"
                    ></Form.Control>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      disabled={validInput}
                      required
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      type="text"
                      placeholder="City"
                    ></Form.Control>
                  </Col>
                  <Col sm>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      disabled={validInput}
                      required
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      type="text"
                      placeholder="State"
                    ></Form.Control>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      disabled={validInput}
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="Email"
                      placeholder="Email"
                    ></Form.Control>
                  </Col>
                  <Col sm>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      disabled={validInput}
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      type="phone"
                      placeholder="Phone"
                    ></Form.Control>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    {!validInput ? <Button type="submit">Submit</Button> : ""}
                  </Col>
                </Row>
              </Form>
              <Row>
                <Col>
                  {validInput ? (
                    <Button
                      variant="secondary"
                      onClick={() => setValidInput(false)}
                    >
                      Edit Form Entries
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
          {validInput ? (
            <>
              <hr />
              <Alert variant="success">Management Agreement</Alert>
              <h3> County of Los Angeles</h3>
              <h3 className="center"> State of California</h3>
              <hr />
              <h2>Management Services Agreement</h2>
              <br />
              <p>
                This Agreement is made effective as of the DATE:
                <span className="date-box">{new Date().toDateString()}</span>,
                by and between{" "}
                <span className="input-box">{handleReturnName()}</span>
                (“BUYER”), of (City and State){" "}
                <span className="input-box">
                  {city}, {state}
                </span>
                and J&F Exportaciones Company (d/b/a Las Azucenas), registered
                in the Trade Register of the Republic of Guatemala, C.A. under
                registration number 104048, folio 116, book 96 of commercial
                companies.
              </p>
              <p>
                For good and valuable consideration, the receipt and sufficiency
                whereof are hereby acknowledged by the undersigned, the parties
                hereto agree as set forth below.
              </p>
              <p>
                In this Agreement, the party who is contracting to receive
                MANAGEMENT services shall be referred to as "BUYER", and the
                party who will be providing the services shall be referred to as
                "Las Azucenas".
              </p>
              <p>
                Las Azucenas has a background in MANAGEMENT and is willing to
                provide services to BUYER based on this background.
              </p>
              <p>
                BUYER desires to have certain services provided by Las Azucenas.
              </p>
              <p>Therefore, the parties agree as follows:</p>
              <p>
                <strong> DESCRIPTION OF SERVICES.</strong> Beginning on the DATE
                listed above, Las Azucenas will provide the following management
                services (collectively, the "Services"):
              </p>
              <p>
                Supervision of all employees or independent contractors employed
                by Las Azucenas. Las Azucenas has retained Javier Garcia
                Esquivel, owner of Las Azucenas Ranch, and his team. All
                personnel will be employees or independent contractors of Las
                Azucenas and not of BUYER, and Las Azucenas shall be responsible
                for all payroll tax withholding and reporting; and
              </p>
              <p>Additionally, Las Azucenas's services will include:</p>
              <p>Description of activities to be carried out</p>
              <p>
                There are important activities to be carried out as part of the
                project aiming to produce mahogany (Swietenia macrophylla King),
                cedar (Cedrella odorata L.) and ciricote (Cordia dodecandra Dc.)
                trees of high economic value
                <ul>
                  <li>Activities for establishing a nursery</li>
                  <ul>
                    <li>a. Collection, handling, and storage</li>
                    <li>
                      b. Establishment of the nursery and production systems
                    </li>
                    <li>
                      c. Work in the nursery for the production of saplings
                    </li>
                    <li>d. Pre-germination treatments</li>
                    <li>e. Sowing</li>
                    <li>
                      f. Transplanting, or separating seedlings once they have
                      reached the desired height
                    </li>
                    <li>g. Use of shade</li>
                    <li>h. Inoculation</li>
                    <li>i. Weed control and fertilization</li>
                  </ul>
                  <li>Activities for establishing a plantation</li>
                  <ul>
                    <li>
                      a. Determine the location and characteristics of the site
                      where planting will take place
                    </li>
                    <li>
                      b. Practical activities for the maintenance of cedar,
                      mahogany, and ciricote plantations
                    </li>
                    <li>
                      c. Pest and disease control, silvicultural treatments
                    </li>
                    <li>d. Protection of plantations</li>
                    <li>e. Marketing</li>
                  </ul>

                  <li>
                    <strong>Removal and Sales of Trees(s)</strong> and ordinary
                    expenses not to exceed 10% of gross sales.
                  </li>
                  <li>
                    The <strong>Remittance of funds</strong> to the buyer, after
                    removal and sale will remain the responsibility of{" "}
                    <strong>Los Santos Consulting, LLC.</strong>
                  </li>
                </ul>
              </p>
              <p>
                Las Azucenas agrees to devote its best effort to the performance
                of its management services. The parties further agree Las
                Azucenas will perform such other services as agreed upon by the
                parties from time to time.
              </p>
              <p>
                <strong>PERFORMANCE OF SERVICES</strong>. The manner in which
                the Services are to be performed and the specific hours to be
                worked by Las Azucenas shall be determined by Las Azucenas.
                BUYER will rely on Las Azucenas to work as many hours as may be
                reasonably necessary to fulfill Las Azucenas's obligations under
                this Agreement.
              </p>
              <p>
                <strong> PAYMENT. </strong> The purchase price as documented in
                the Bill of Sale agreement, between BUYER and Los Santos,
                includes a fee that covers the services outlined in this
                document. This is a one-time, inclusive, fee for services.
              </p>
              <p>
                <strong>TERMINATION.</strong> This Agreement may be terminated
                by either party upon 90 days written notice to the other party.
              </p>
              <p>
                <strong>RELATIONSHIP OF PARTIES. </strong> It is understood by
                the parties that Las Azucenas is an independent contractor with
                respect to BUYER, and not an employee, partner or joint venturer
                of BUYER. BUYER will not provide fringe benefits, including
                health insurance benefits, paid vacation, or any other employee
                benefit, for the benefit of Las Azucenas.
              </p>
              <p>
                <strong>DISCLOSURE.</strong> Las Azucenas is required to
                disclose any outside activities or interests, including
                ownership or participation in the development of prior
                agreements, that conflict or may conflict with the best
                interests of BUYER. Prompt disclosure is required under this
                paragraph if the activity or interest is related, directly or
                indirectly, to:
                <br />- any activity that Las Azucenas may be involved with on
                behalf of BUYER
              </p>
              <p>
                <strong> EMPLOYEES.</strong> Las Azucenas's employees, if any,
                who perform services for BUYER under this Agreement shall also
                be bound by the provisions of this Agreement.
              </p>
              <p>
                <strong>INJURIES. </strong> Las Azucenas acknowledges Las
                Azucenas's obligation to obtain appropriate insurance coverage
                for the benefit of Las Azucenas (and Las Azucenas's employees,
                if any). Las Azucenas waives any rights to recovery from BUYER
                for any injuries that Las Azucenas (and/or Las Azucenas's
                employees) may sustain while performing services under this
                Agreement and that is a result of the negligence of Las Azucenas
                or Las Azucenas's employees.
              </p>
              <p>
                <strong>WARRANTY. </strong>
                The Company provides a base warranty for each tree at no
                additional cost, which guarantees that the tree will achieve and
                maintain healthy growth consistent with species expectations and
                meet projected volume expectations at maturity. The tree will
                receive professional care and maintenance as outlined in this
                Management Services Agreement. Under this base warranty, the
                Company will replace any tree that fails to meet growth and
                health standards due to biological factors with a replacement
                tree of similar age and species, within 60 days after claim
                approval. However, this base warranty excludes losses resulting
                from natural disasters, fire, pests affecting the broader
                region, theft or vandalism, acts of war or civil unrest, or any
                other acts of God. Such excluded events may be covered through
                the optional Comprehensive Protection Plan available for
                purchase separately.
              </p>
              <p>
                <strong> INDEMNIFICATION.</strong> Las Azucenas agrees to
                indemnify and hold harmless BUYER from all claims, losses,
                expenses, and fees including attorney fees, costs, and judgments
                that may be asserted against BUYER that result from the acts or
                omissions of Las Azucenas, Las Azucenas's employees, if any, and
                Las Azucenas's agents.
              </p>
              <p>
                <strong> ASSIGNMENT. </strong> Las Azucenas's obligations under
                this Agreement may not be assigned or transferred to any other
                person, firm, or corporation without the prior written consent
                of BUYER.
              </p>
              <p>
                <strong> RETURN OF RECORDS.</strong> Upon termination of this
                Agreement, Las Azucenas shall deliver to BUYER all records,
                notes, data, memoranda, and models of any nature that are in Las
                Azucenas's possession or under Las Azucenas's control and that
                are BUYER’s property or relate to BUYER’s business.
              </p>
              <p>
                <strong>NOTICES.</strong> All notices required or permitted
                under this Agreement shall be in writing and shall be deemed
                delivered when delivered in person or deposited in the United
                States mail, postage prepaid, addressed as follows:
              </p>
              <p>
                IF for : <span className="input-box">{handleReturnName()}</span>{" "}
                <strong>(BUYER'S Name)</strong>
                <br />
                BUYER ADDRESS:{" "}
                <span className="input-box">
                  {address} {city}, {state}
                </span>
                <br />
                EMAIL: <span className="input-box">{email}</span>
                <br />
                PHONE NUMBER: <span className="input-box">{phone}</span>
              </p>
              <p>
                IF for Las Azucenas:
                <br />
                <br />
                Javier G Esquivel
                <br />
                <br />
                Personal Identification Document (“DPI”) number 1832796462201
                and having a residential address in Ciudad Satélite, Santa Elena
                Flores Peten, Guatemala.
                <br />
                <br />
                Los Santos Consulting will remain the primary contact with the
                BUYER.
              </p>
              <p>
                Such address may be changed from time to time by either party by
                providing written notice to the other in the manner set forth
                above.
              </p>
              <p>
                <strong> ENTIRE AGREEMENT. </strong> This Agreement may be
                modified or amended if the amendment is made in writing and is
                signed by both parties.
              </p>
              <p>
                <strong> AMENDMENT. </strong> This Agreement may be modified or
                amended if the amendment is made in writing and is signed by
                both parties.
              </p>
              <p>
                <strong>SEVERABILITY. </strong> If any provision of this
                Agreement shall be held to be invalid or unenforceable for any
                reason, the remaining provisions shall continue to be valid and
                enforceable. If a court finds that any provision of this
                Agreement is invalid or unenforceable, but that by limiting such
                provision it would become valid and enforceable, then such
                provision shall be deemed to be written, construed, and enforced
                as so limited.
              </p>
              <p>
                <strong> NO WAIVER OF CONTRACTUAL RIGHT. </strong> The failure
                of either party to enforce any provision of this Agreement shall
                not be construed as a waiver or limitation of that party's right
                to subsequently enforce and compel strict compliance with every
                provision of this Agreement.
              </p>
              <p>
                <strong> APPLICABLE LAW. </strong> This Agreement shall be
                governed by the laws of the State of California, USA.
              </p>
              <p>
                <strong> SIGNATORIES. </strong> This Agreement shall be executed
                on the behalf of BUYER OF TREE(S) and on behalf of Las Azucenas
                by Javier G Esquivel, its Owner.
              </p>
              <Alert>
                <Form.Check // prettier-ignore
                  type={"checkbox"}
                  id={`acknowledge`}
                  ref={checkboxRef}
                  label={
                    <>
                      I acknowledge that I have received and read the{" "}
                      <a
                        href="https://azucenas.org/azucenas_white_paper.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Las Azucenas white paper
                      </a>
                      . This overview outlines key details about the Las
                      Azucenas timber cultivation project, including:
                    </>
                  }
                  onChange={(e) => setAcknowledge(e.target.checked)}
                />
              </Alert>
              <ul>
                <li>Background on the company and management team</li>
                <li>Track record</li>
                <li>Location and species of trees being offered</li>
                <li>Growth cycles and estimated timeline to maturity</li>
                <li>Historical present and proforma tree prices</li>
                <li>Explanation of land lease arrangements</li>
                <li>Responsibilities for crop care and maintenance</li>
                <li>Commissions, fees, and potential investor exit options</li>
                <li>
                  Risk factors such as diseases, pests, fire, weather events,
                  and market fluctuations
                </li>
              </ul>

              <Alert>
                <Alert.Heading>Party receiving Services:</Alert.Heading>
                <hr />
                <p></p>
                <p>BUYER Signature</p>
                <p>By:</p>
                {sign === "" ? (
                  <Button
                    size="lg"
                    variant="primary"
                    onClick={() => setSign("open")}
                  >
                    Click To Sign
                  </Button>
                ) : signature === "" ? (
                  <>
                    <Alert variant="secondary">
                      Select A Signature To Use From the Dropdown Menu
                      <hr />
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          Choose a Signature
                        </Dropdown.Toggle>

                        <Dropdown.Menu onChange={(e) => console.log(e)}>
                          <Dropdown.Item
                            id="sig1"
                            onClick={() => createTextSignature("sig1")}
                          >
                            {firstName + " " + lastName}
                          </Dropdown.Item>
                          <Dropdown.Item
                            id="sig2"
                            onClick={() => createTextSignature("sig2")}
                          >
                            {firstName + " " + lastName}
                          </Dropdown.Item>
                          <Dropdown.Item
                            id="sig3"
                            onClick={() => createTextSignature("sig3")}
                          >
                            {firstName + " " + lastName}
                          </Dropdown.Item>
                          <Dropdown.Item
                            id="my-own"
                            onClick={() => setShowDraw(true)}
                          >
                            Draw my own
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Alert>

                    {!showDraw ? (
                      ""
                    ) : (
                      <div>
                        <p>
                          Draw your signature in the white space below, then
                          click 'Accept Signature'
                        </p>
                        <br />
                        <div className="signature-block">
                          <SignatureCanvas
                            penColor="Blue"
                            canvasProps={{ className: "sigCanvas" }}
                            ref={pad}
                          />
                        </div>
                        <Button onClick={() => captureSig()}>
                          Accept Signature
                        </Button>
                        <br />
                        <br />
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    <img src={signature} style={{ maxWidth: "100%" }} />
                    <br />
                    <p
                      style={{
                        fontStyle: "italic",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setSignature("")}
                    >
                      Click here to redo signature.
                    </p>
                  </div>
                )}

                <p>Date: {new Date().toLocaleDateString()}</p>
                <p>
                  Buyer’s Printed Name:{" "}
                  <span className="input-box">
                    {firstName} {lastName}
                  </span>
                </p>
                {forCompany ? (
                  <span className="input-box">
                    On behalf of {organizationName}
                  </span>
                ) : (
                  ""
                )}
              </Alert>
              <Alert variant="secondary">
                <Alert.Heading>Party providing services:</Alert.Heading>
                <hr />
                J&F Exportaciones Company (Las Azucenas)
                <br />
                <br />
                <p>
                  By: ___________________________________ Date:
                  __________________
                  <br />
                  Javier G Esquivel
                  <br />
                </p>
              </Alert>
              <Alert variant="secondary">
                <Alert.Heading>
                  Managing sale proceeds and disbursements:
                </Alert.Heading>
                <hr />
                Los Santos Consulting, LLC
                <br />
                <br />
                <p>
                  By: ___________________________________ Date:
                  __________________
                  <br />
                  Guillermo A Romero
                  <br />
                </p>
              </Alert>

              <div className="button-container">
                <Button size="lg" onClick={() => handleSubmit()}>
                  Complete Document
                </Button>
              </div>
              <Modal show={show} onHide={() => handleClose()}>
                <Modal.Header closeButton>
                  <Modal.Title>Finalize Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  You have completed the required fields. Ready to submit?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => handleClose()}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={() => handleSubmit()}>
                    Submit Document
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <Alert variant="warning">
              Click submit on the form above and your document will load here.
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
}
