import axios from "axios";
import { useState } from "react";
import { Col, Row, Form, Button, Table, Card, Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SelectTrees from "../../selectTrees";
import "./newClient.scss";

export default function NewClient() {
  const [showSelect, setShowSelect] = useState(false);
  const [selected, setSelected] = useState([]);
  const [useCustomTotal, setUseCustomTotal] = useState(false);
  const [customTotal, setCustomTotal] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const history = useHistory();

  const calculateTotal = () => {
    let total = selected.reduce(
      (previous, current) => previous + +current.current_value,
      0
    );
    return total.toFixed();
  };
  const handleChangeTotalType = (e) => {
    setCustomTotal(0);
    setUseCustomTotal(!useCustomTotal);
  };
  const handleSelected = (trees) => {
    setSelected(trees);
  };

  const validateInput = () => {
    var re = /\S+@\S+\.\S+/;
    if (name === "") {
      alert("Please enter a name");
      return;
    }
    if (!re.test(email)) {
      alert("Email Input is invalid");
      return;
    }
    handleSubmit();
  };
  const handleSubmit = () => {
    try {
      axios
        .post(process.env.REACT_APP_API + "/admin/onboarding/new", {
          name,
          email,
          trees: selected,
          custom_total: customTotal,
          default_total: calculateTotal(),
        })
        .then((res) => {
          Swal.fire({ icon: "success", text: res.data.message });
          history.push("/onboarding");
        });
    } catch {
      Swal.fire({
        icon: "error",
        text: "There was an error with your request",
      });
    }
  };

  return (
    <div className="admin-dash-content new-client">
      <Row className="">
        <Col>
          <Row>
            <Col>
              <Alert>
                <h2>Onboard New Client</h2>
              </Alert>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Form>
                <Form.Group>
                  <Form.Label>First and Last Name</Form.Label>
                  <Form.Control
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="test"
                    placeholder="First and Last Name"
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  type="email"
                  placeholder="Enter email address"
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Button onClick={() => setShowSelect(true)}>
                Select Trees For BOS
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              {selected.length > 0 ? (
                <Row>
                  <Col>
                    <Form.Label>Set Bill of Sale Total</Form.Label>
                    <Form.Select
                      onChange={(e) => handleChangeTotalType(e.target.value)}
                    >
                      <option value={false}>Use System Total</option>
                      <option value={true}>Custom Total</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Label>Bill of Sale Total</Form.Label>
                    {useCustomTotal ? (
                      <Form.Control
                        id="system"
                        type="number"
                        value={customTotal}
                        onChange={(e) => setCustomTotal(e.target.value)}
                        placeholder="Sales total"
                      />
                    ) : (
                      <div className="form-control" aria-disabled="true">
                        ${calculateTotal()}
                      </div>
                    )}
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <br />
          {selected.length > 0 ? (
            <Row>
              <Col>
                <Button onClick={() => validateInput()} variant="success">
                  Complete Onboarding Setup
                </Button>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Col>
        <Col>
          <Card>
            <Card.Header>Instructions</Card.Header>
            <Card.Body>
              <p>
                Completing this form will create an onboarding link that will be
                sent to the new client. The onboarding process includes the
                Management Agreement and Bill of Sale.
              </p>
              <ul>
                <li>Enter the New Client's First and Last Name</li>
                <li>Enter their Email Address</li>
                <ul>
                  <li>
                    This email will be used to send the onboarding instructions
                    to the new client.
                  </li>
                </ul>
                <li>Select Trees For Their Bill Of Sale</li>
                <li>
                  The current catalogue value of the trees is calculated for
                  you, however you can override the total value for the BOS by
                  changing the dropdown to 'Use Custom Total'
                </li>
              </ul>
              <p>
                To Complete the Onboarding Setup, press the "Complete Onboarding
                Setup" button.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <hr />
      <Row>
        <Col>
          <Table>
            <thead>
              <th>Tree ID</th>
              <th>Type</th>
              <th>Value</th>
            </thead>
            <tbody>
              {selected.map((tree, index) => (
                <tr key={tree + index}>
                  <td>{tree.tree_id}</td>
                  <td>{tree.tree_type}</td>
                  <td>{tree.current_value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <SelectTrees
        handleReturn={handleSelected}
        setShowSelect={setShowSelect}
        showSelect={showSelect}
        title="Select Trees For New Onboarding Client"
      ></SelectTrees>
    </div>
  );
}
