import axios from "axios";
import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

export default function OnboardingCenter() {
  const [data, setData] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    await axios
      .get(process.env.REACT_APP_API + "/admin/onboarding/all")
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch(() => {
        alert("unable to get data");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const copyToClipboard = (id) => {
    const link = `https://azucenas.net/onboarding/${id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const deleteOnboarding = async (onboarding) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete onboarding for ${onboarding.name}?`
    );
    if (confirmed) {
      await axios
        .delete(
          process.env.REACT_APP_API +
            "/admin/onboarding/delete/" +
            onboarding.onboarding_id
        )
        .then(() => {
          Swal.fire({
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
            title: "Success",
            text: "Deleted Onboarding",
          });
        })
        .finally(() => {
          getData();
        });
    }
  };
  return loading ? (
    <div className="admin-dash-content">
      Loading... <br />
      <Spinner animation="border" variant="success" size="lg"></Spinner>
    </div>
  ) : (
    <div className="admin-dash-content">
      <h2>Onboarding</h2>
      <hr />
      <Button variant="success" onClick={() => history.push("/new-client")}>
        Create New Onboarding
      </Button>
      <hr />
      <Col>
        {data.map((item, index) => (
          <Card style={{ marginBottom: "1em" }}>
            <Card.Header>
              {item.name} | {item.email}
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Button
                    variant="warning"
                    onClick={() => copyToClipboard(item.onboarding_id)}
                  >
                    Copy Onboarding Link
                  </Button>
                  {!item.mgmt_admin_sign && !item.bos_admin_sign && (
                    <Button
                      style={{ marginLeft: "1rem" }}
                      variant="danger"
                      onClick={() => deleteOnboarding(item)}
                    >
                      Delete
                    </Button>
                  )}

                  <br />
                  <br />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ListGroup>
                    <ListGroupItem>
                      <Button
                        onClick={() =>
                          history.push(
                            "/document/" + item.management_id + "/mgmt"
                          )
                        }
                      >
                        Management Agreement
                      </Button>
                    </ListGroupItem>
                    <ListGroupItem>
                      (Client){" "}
                      {item.mgmt_client_sign ? (
                        <Badge>Completed</Badge>
                      ) : (
                        <Badge bg="danger">Not Complete</Badge>
                      )}
                    </ListGroupItem>
                    <ListGroupItem>
                      (Admin){" "}
                      {item.mgmt_admin_sign ? (
                        <Badge>Completed</Badge>
                      ) : (
                        <Badge bg="danger">Not Complete</Badge>
                      )}
                    </ListGroupItem>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup>
                    <ListGroupItem>
                      <Button
                        onClick={() =>
                          history.push("/document/" + item.bos_id + "/bos")
                        }
                      >
                        Bill of Sale
                      </Button>
                    </ListGroupItem>

                    <ListGroupItem>
                      (Client){" "}
                      {item.bos_client_sign ? (
                        <Badge>Completed</Badge>
                      ) : (
                        <Badge bg="danger">Not Complete</Badge>
                      )}
                    </ListGroupItem>

                    <ListGroupItem>
                      (Admin){" "}
                      {item.bos_admin_sign ? (
                        <Badge>Completed</Badge>
                      ) : (
                        <Badge bg="danger">Not Complete</Badge>
                      )}
                    </ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </Col>
    </div>
  );
}
