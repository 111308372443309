import { useState } from "react";
import { Alert, Badge, Button, Form, ListGroup, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import SelectTrees from "../../selectTrees";
import './newBos.scss'
import Swal from "sweetalert2";

export default function NewBOS({ name }) {
    const [showSelect, setShowSelect] = useState(false);
    const [selected, setSelected] = useState([])
    const params = useParams()
    const [useCustomTotal, setUseCustomTotal] = useState(false)
    const [customTotal, setCustomTotal] = useState(0)
    const history = useHistory()
    const handleSelected = (trees) => {
        setSelected(trees)
    }

    const calculateTotal = () => {
        let total = selected.reduce((previous, current) => previous + +current.current_value, 0)
        return total.toFixed()
    }
    const handleChangeTotalType = (e) => {
        setCustomTotal(0)
        setUseCustomTotal(!useCustomTotal)

    }
    const handleSubmitBOS = () => {
        axios.post(process.env.REACT_APP_API + '/admin/user/bos/new', {
            name,
            owner_id: +params.id,
            custom_total: useCustomTotal ? customTotal : null,
            default_total: calculateTotal(),
            trees: selected
        }).then((res) => {
            if (res.data.message === 'success') {
                Swal.fire({
                    icon: "success",
                    timer: 1500
                }).then(() => {
                    history.push(`/owner/${params.id}`)
                    window.location.reload()
                })
            } else {
                Swal.fire({
                    icon: "error",
                    title: "There was an error with your request"
                })
            }
        })
    }


    return (
        <div className="new-bos">
            <Alert>
                Create New Bill of Sale
            </Alert>
            <div className="tool-bar">
                <Button onClick={() => setShowSelect(true)}>Select Trees For BOS</Button>
                {selected.length > 0 ?
                    <>
                        <div className="tool">
                            <Form.Select onChange={e => handleChangeTotalType(e.target.value)}>
                                <option value={false}>Use System Total</option>
                                <option value={true}>Custom Total</option>
                            </Form.Select>
                        </div>
                        <div className="tool">
                            {useCustomTotal ?
                                <Form.Control id="system" type="number" value={customTotal} onChange={(e) => setCustomTotal(e.target.value)} placeholder="Sales total" /> : <div className="form-control" aria-disabled="true">${calculateTotal()}</div>
                            }
                        </div>
                        <div className="tool">
                            <Button variant="success" onClick={() => handleSubmitBOS()}>Create Bill of Sale</Button>
                        </div>

                    </> : ''
                }

            </div>
            <hr />
            <Table>
                <thead>
                    <th>Tree ID</th>
                    <th>Type</th>
                    <th>Value</th>
                </thead>
                <tbody>

                    {selected.map((tree, index) => (
                        <tr key={tree + index}>
                            <td>{tree.tree_id}</td>
                            <td>{tree.tree_type}</td>
                            <td>{tree.current_value}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <SelectTrees title="Select Trees To Add to Bill of Sale" showSelect={showSelect} setShowSelect={setShowSelect} handleReturn={handleSelected} />

        </div >
    )
}